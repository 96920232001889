
//$clight:    rgb( 248, 249, 249 );
//$cdark:    rgb( 214, 234, 248);

//$font-family-sans-serif:      "Microsoft YaHei", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$clight:   #F0F8FF;
$cdark:    #ADDFFF;

$primary:       $cdark;
$secondary:     #EBF4FA;//water

//$body-bg:     #f1fbf4;

.hjnav{
	background: linear-gradient(to right, rgba($secondary, 0.8), rgba($primary, 0.9));
}

.bg-grd{
	background: linear-gradient(to bottom, rgba($secondary, 0.9), rgba($primary, 0.7));
}
.bg-grd2{
	background: linear-gradient(to bottom, rgba($primary, 0.9), rgba($secondary, 0.7));
}
.bg-aliceblue{background-color:AliceBlue;}
.red{color:red;}


.a-hj{
	text-decoration: none;
	color:blue;
	border-bottom: 1px solid blue;
}

.overflow-break{
	overflow-wrap: break-word;word-wrap: break-word;-ms-word-break: break-all;word-break: break-all;word-break: break-word;
}

$font-size-sm:  0.8rem;


//@import "../node_modules/rfs/scss";
@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/bootstrap";

//header
.nav-item a{
	color:#34282C;
	&:hover{color:white; font-weight:bold;}
}

//home
.card-link a{
  @include media-breakpoint-down(md) {font-size: 0.7rem;};
  text-decoration:none;color:#996666;
  &:hover{color:#CC9933;}	
}

.fs-1-08{
	@include media-breakpoint-down(md) {font-size: 0.8rem;};
}
.fs-08-06{
	font-size: 0.8rem;
	@include media-breakpoint-down(md) {font-size: 0.6rem;};
}
.fs-09{
	font-size: 0.9rem;
}
.fs-08{
	font-size: 0.8rem;
}
.p-indent{
	text-indent:2em;
}
.inqury{
	svg{
		width:100%;
		stroke:$primary;
	}
}

.a-plain{
text-decoration: none;
color: inherit;	
}

//svg{
	.toparticle{
		font-size:1rem;
		color: #f1c40f ;
		//stroke:brown;		
	}
//}
//.no-underline{
//	text-decoration: none;
//}

.showcase{
	h5{
		text-decoration: none;
		color:$gray-800;
		@include media-breakpoint-down(md) {font-size: 0.8rem;};
	}	
}

//services
.service-card
{
	h5{
		text-align: center;
		font-weight: bold;
	}
	p{
		text-indent:2em;
		font-size:0.9rem;
		margin:0;
	}
	.red{
		color:red;
	}
}

//单行justify
.jsty{
	text-align: justify;
	//height: 2em;
    //overflow: hidden;
}
.jsty:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.charge_itm {
	li{
		line-height:2em;
		border-bottom: 1px dashed #5F9EA0;
	}
}
.bot-dash{
	line-height:2em;
	border-bottom: 1px dashed #5F9EA0;
}
.accordion-faq{
	.accordion-button{font-size: 0.9rem;}
	.accordion-body{font-size: 0.8rem;}
}
//.other-services{
//	a{
//		color:blue;
//		border-bottom: 1px solid blue;
//	}
//}


//Footer
.hjfooter{
	background-color: $green-100;
	color:$gray-800;
	a{
		color:$gray-800;
	}
}
//.showcase::before{
//	content:url('../node_modules/images/cases.png');
//} 



